import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { HomeRoutes } from './homeRoutes/homeRoutes'
import { ProfileRoutes } from './profileRoutes/profileRoutes'
import { PrivateReferenceRoutes } from './privateReferenceRoutes/privateReferenceRoutes'

const NotFound = lazy(() => import('../../pages/404'))

export const privateRoutes = [
	...HomeRoutes,
	...ProfileRoutes,
	...PrivateReferenceRoutes,
]

interface IProps {
	rol: string
}

export default function PrivatePages({ rol }: IProps) {
	return (
		<Routes>
			<Route path="*" element={<NotFound />} />
			{privateRoutes.map((routes: any) => {
				if (routes.rols.find((rols: any) => rols === rol || rols === '*')) {
					return (
						<Route
							key={routes.path}
							path={routes.path}
							element={<routes.element />}
						/>
					)
				}
				return null
			})}
		</Routes>
	)
}
