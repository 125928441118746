import React from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import './style.css'

import { GridContainer, GridItem } from '../elements/grid'
import Logo from '../../assets/logoSorvo.svg'
import InstagramIcon from '../../assets/logos/instagram.svg'
import FacebookIcon from '../../assets/logos/facebook.svg'
import WhatsAppIcon from '../../assets/logos/whatsapp.svg'
import PlaceToPayIcon from '../../assets/logos/place-to-pay.svg'
import { getLoggedUser } from '../../redux/slices/loggedUserSlice'
import { TextSubTitle, TextTitle } from './index.styled'

type FooterProps = {
	isMedium: boolean
	isInAddressPage: boolean
}
export default function Footer({ isMedium, isInAddressPage }: FooterProps) {
	const userManager = useSelector(getLoggedUser)

	return (
		<Box
			sx={{
				position: 'relative',
				zIndex:
					(isInAddressPage && isMedium) || userManager.isOpenSelect
						? 0
						: `${1650} !important`,
			}}
		>
			<Box
				sx={{
					background: 'rgba(18, 18, 18, 1)',
					color: 'white',
					padding: '30px 0',
					marginTop: userManager?.logged ? '30px' : '0',
				}}
			>
				<Box sx={{ position: 'relative', zIndex: '1' }}>
					<GridContainer>
						<GridItem lg={12} md={12}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									position: 'relative',
									top: '-70px',
								}}
							>
								<Box
									sx={{
										background: 'rgba(18, 18, 18, 1)',
										position: 'absolute',
										width: '100px',
										height: '100px',
										textAlign: 'center',
										borderRadius: '100%',
									}}
								>
									<IconButton
										size="small"
										color="inherit"
										onClick={() =>
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
										}
									>
										<KeyboardArrowUpIcon sx={{ fontSize: '50px' }} />
									</IconButton>
								</Box>
							</Box>
						</GridItem>
					</GridContainer>
				</Box>
				<Box
					sx={{
						position: 'relative',
						zIndex: '2',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: '35px',
						}}
					>
						<Box sx={{ background: '#E9E9E9', height: '1px', width: '100%' }} />
						<Box sx={{ textAlign: 'center' }}>
							<img alt="Logo" src={Logo} width="154px" height="74px" />
						</Box>
						<Box sx={{ background: '#E9E9E9', height: '1px', width: '100%' }} />
					</Box>
					<Box sx={{ padding: { xs: '0 16px', md: '0 80px' } }}>
						<GridContainer justifyCenter spacing={0}>
							<GridItem
								lg={3}
								md={3}
								sm={6}
								sx={{
									marginBottom: '35px',
								}}
							>
								<Box className="footer-link-container">
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'end',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												gap: '15px',
											}}
										>
											<TextTitle>QUIÉNES SOMOS</TextTitle>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													gap: '10px',
												}}
											>
												<TextSubTitle
													onClick={() =>
														window.open(
															process.env.REACT_APP_URL_TERMINOS_CONDICIONES
														)
													}
												>
													Términos y condiciones
												</TextSubTitle>
												<TextSubTitle
													onClick={() =>
														window.open(
															process.env.REACT_APP_URL_POLITICA_PRIVACIDAD
														)
													}
												>
													Políticas de privacidad
												</TextSubTitle>
												<TextSubTitle
													onClick={() =>
														window.open(process.env.REACT_APP_URL_VINUTOS)
													}
												>
													Vinutos
												</TextSubTitle>
											</Box>
										</Box>
									</Box>
								</Box>
							</GridItem>
							<GridItem
								lg={3}
								md={3}
								sm={6}
								sx={{
									marginBottom: '35px',
								}}
							>
								<Box className="footer-link-container">
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'end',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												gap: '15px',
											}}
										>
											<TextTitle>CENTRO DE AYUDA</TextTitle>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													gap: '10px',
												}}
											>
												<TextSubTitle
													onClick={() =>
														window.open(process.env.REACT_APP_URL_SOPORTE)
													}
												>
													Soporte
												</TextSubTitle>
												<TextSubTitle
													onClick={() =>
														window.open(
															process.env.REACT_APP_URL_PREGUNTAS_FRECUENTES
														)
													}
												>
													Preguntas frecuentes
												</TextSubTitle>
											</Box>
										</Box>
									</Box>
								</Box>
							</GridItem>
							<GridItem
								lg={3}
								md={3}
								sm={6}
								sx={{
									marginBottom: '35px',
								}}
							>
								<Box
									className="footer-link-container"
									sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'end',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												maxWidth: { xs: '270px', md: '220px' },
												width: '100%',
												gap: '15px',
											}}
										>
											<TextTitle>
												PAGA CON TUS TARJETAS PREFERIDAS EN PLACETOPAY
											</TextTitle>
											<img
												src={PlaceToPayIcon}
												alt={`${PlaceToPayIcon}`}
												width="165px"
												height="61px"
											/>
										</Box>
									</Box>
								</Box>
							</GridItem>
							<GridItem lg={3} md={3} sm={6}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: { xs: 'flex-start', md: 'flex-end' },
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											gap: '15px',
											alignItems: { xs: 'flex-start', md: 'flex-end' },
										}}
									>
										<TextTitle>ENCUÉNTRANOS EN:</TextTitle>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												gap: '20px',
												width: '100%',
												paddingBottom: { xs: '20px', md: 0 },
											}}
										>
											<img
												src={InstagramIcon}
												alt={`${InstagramIcon}`}
												width="32px"
												height="32px"
												onClick={() =>
													window.open(process.env.REACT_APP_URL_INSTAGRAM)
												}
												style={{
													cursor: 'pointer',
												}}
											/>
											<img
												src={FacebookIcon}
												alt={`${FacebookIcon}`}
												width="32px"
												height="32px"
												onClick={() =>
													window.open(process.env.REACT_APP_URL_FACEBOOK)
												}
												style={{
													cursor: 'pointer',
												}}
											/>
											<img
												src={WhatsAppIcon}
												alt={`${WhatsAppIcon}`}
												width="32px"
												height="32px"
												onClick={() =>
													window.open(process.env.REACT_APP_URL_WHATSAPP)
												}
												style={{
													cursor: 'pointer',
												}}
											/>
										</Box>
									</Box>
								</Box>
							</GridItem>
						</GridContainer>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					background: 'rgba(39, 38, 38, 1)',
					color: 'white',
					padding: { xs: '15px', md: '15px 80px' },
					display: 'flex',
					justifyContent: { xs: 'center', md: 'flex-start' },
					width: '100%',
				}}
			>
				<Typography fontSize="12px">
					© {new Date().getFullYear()} Sorvo. Todos los derechos reservados
				</Typography>
			</Box>
		</Box>
	)
}
